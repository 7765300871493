import React from "react";
import "./index.css";

class Design extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeLink: "About"
    };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    this.setState({ activeLink: e.target.textContent });
  }

  render() {
    const { document } = this.props;
    const { activeLink } = this.state;
    return (
      <div className="design-wrapper">
        <div className="design-header">
          <h2
            className={`${activeLink === "About" && "active-link"}`}
            onClick={this.handleClick}
          >
            {document.data["about"][0].text}
          </h2>
        </div>
        <div className="design-content">
          <div className="design-about">
            {document.data["page_desc"].map((desc, i) => {
              return (
                <p key={i} className="design-about-desc">
                  {desc.text}
                </p>
              );
            })}

            {document.data["content_title"].map((title, i) => {
              return <h4 key={i}>{title.text}</h4>;
            })}

            <div className="design-about-approach">
              {document.data["approach_item"].map((approach, i) => {
                return (
                  <div key={i} className="approach-item">
                    {approach["approach_title"].map((title, j) => {
                      return <h3 key={j}>{title.text}</h3>;
                    })}
                    {approach["approach_text"].map((paragraph, k) => {
                      return <p key={k}>{paragraph.text}</p>;
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Design;
