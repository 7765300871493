import React from "react";
import { PostShower } from "../../PostShower";
import "./index.css";

class SomethingElse extends React.Component {
  state = {
    end: 2
  };

  isBottom(el) {
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  }

  componentDidMount() {
    document.addEventListener("scroll", this.trackScrolling);
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.trackScrolling);
  }

  trackScrolling = () => {
    console.log("hello");
    const wrappedElement = document.getElementById("somethingElse");
    console.log(wrappedElement);
    if (this.isBottom(wrappedElement)) {
      console.log("header bottom reached");
      document.removeEventListener("scroll", this.trackScrolling);
    }
  };

  render() {
    const {
      document,
      currentPostContent,
      showingPost,
      vintage_posts
    } = this.props;
    const { start, end } = this.state;
    return currentPostContent && showingPost ? (
      <PostShower>
        <h1>{currentPostContent}</h1>
      </PostShower>
    ) : (
      <div
        className="something-else-wrapper"
        id={`somethingElse ${document.id}`}
      >
        <div className="something-else-header">
          <h2>{document.data["page_title"][0].text}</h2>
        </div>
        <div className="something-else-description">
          {document.data["page_description"].map((paragraph, i) => {
            return <p key={i}>{paragraph.text}</p>;
          })}
        </div>
        {vintage_posts.map((post, i) => {
          return (
            <div key={i} className="something-else-post-item" id={i}>
              <div className="something-else-post-date">
                {post.time_stamp.map((time, j) => (
                  <p key={j}>{time.text}</p>
                ))}
              </div>
              <div className="something-else-post-image">
                <img alt="" src={post["post_image"].url} />
              </div>
              <div className="something-else-post-title">{post.post_title}</div>
              {post.post_content.map((content, k) => (
                <strong key={k}>{content.text}</strong>
              ))}
              <div className="something-else-post-link">
                <a href={post.product_link.url}>Direct Link</a>
                <br />
                <a href={post.product_link.url}>{post.product_link.url}</a>
                <br />
              </div>
            </div>
          );
        })}
        <div
          className="loadmore"
          onClick={() => {
            this.setState(
              prevState => ({
                end: prevState.end + 1
              }),
              () => this.props.getNewPosts(end)
            );
          }}
        >
          Load more posts
        </div>
      </div>
    );
  }
}

export default SomethingElse;
