export default {

  apiEndpoint: 'https://20ltd.cdn.prismic.io/api/v2',

  // -- Access token if the Master is not open
  // accessToken: 'MC5YRkYxVGhBQUFCOEFSRnZt.77-9ekfvv73vv73vv70U77-977-9f2fvv71NVe-_ve-_vQ9XWUnvv73vv73vv73vv70zRe-_vRbvv70VPhY',

  // OAuth
  // clientId: 'xxxxxx',
  // clientSecret: 'xxxxxx',

  // -- Links resolution rules
  // This function will be used to generate links to Prismic.io documents
  // As your project grows, you should update this function according to your routes
  linkResolver(doc) {
    if (doc.type === 'page') return `/page/${doc.uid}`;
    return '/';
  },
};
