import React, { Component } from "react";
import Carousel from "nuka-carousel";
import "./index.css";
import { SidebarOverflow } from "../../SidebarOverflow";
import Design from "../Design";
import Contact from "../Contact";
import Sourcing from "../Sourcing";
import Loading from "../../Loading";
import SomethingElse from "../SomethingElse";
import NotFound from "../../../NotFound";
import LogoSvg from "../../../assets/images/20ltd.png";

class HomePage extends Component {
  initState = {
    isOpen: false,
    contentName: "",
    document: null,
    notFound: false,
    showingPost: false,
    currentPostID: null,
    currentPostContent: null,
    navigationLinks: [],
    closing: true,
    startingPostPageIndex: 0,
    endPostPageIndex: 1,
    vintage_posts: []
  };

  constructor(props) {
    super(props);

    this.state = {
      ...this.initState,
      isLoading: true,
      isLoadingImages: true
    };

    this.imgCount = 0;

    this.goHomePage = this.goHomePage.bind(this);
    this.showPostsList = this.showPostsList.bind(this);
    this.closeSidebar = this.closeSidebar.bind(this);
    this.setCurrentPostID = this.setCurrentPostID.bind(this);
    this.fetchPostContent = this.fetchPostContent.bind(this);
  }

  componentDidMount() {
    if (this.props.prismicCtx) {
      this.props.prismicCtx.api.getSingle(
        "navigation_links",
        {},
        (err, document) => {
          if (document) {
            this.setState({ navigationLinks: document.data["link_item"] });
          }
        }
      );
      this.props.prismicCtx.api.getSingle("images", {}, (err, document) => {
        if (document) {
          const imageAll = document.data["image-carousel"].length;
          document.data["image-carousel"].forEach(image => {
            const img = new Image();
            img.onload = () => {
              this.imgCount++;
              if (this.imgCount === imageAll) {
                this.setState({ isLoading: false });
              }
            };
            img.src = image.image.url;
          });
          let array = document.data["image-carousel"];
          array.sort(() => Math.random() - 0.5);

          this.setState({
            images: array,
            isLoadingImages: false
          });
        }
      });
    }
  }

  handleClick = contentName => {
    const isOpen = !!contentName;

    if (this.state.contentName && this.state.contentName !== contentName) {
      this.setState({ closing: true });
      setTimeout(() => {
        this.openTab(isOpen, contentName);
      }, 500);
    } else {
      this.openTab(isOpen, contentName);
    }
  };

  openTab(isOpen, contentName) {
    this.setState(
      {
        ...this.initState,
        closing: false,
        navigationLinks: this.state.navigationLinks,
        contentName,
        isOpen
      },
      this.fetchContent
    );
  }

  goHomePage() {
    if (this.state.contentName) {
      this.setState({ closing: true });
      setTimeout(() => {
        this.setState({
          ...this.initState,
          navigationLinks: this.state.navigationLinks
        });
      }, 500);
    } else {
      this.setState({
        ...this.initState,
        navigationLinks: this.state.navigationLinks
      });
    }
  }

  closeSidebar = () => {
    this.setState({ closing: true });
    setTimeout(() => {
      this.setState({
        ...this.initState,
        navigationLinks: this.state.navigationLinks
      });
    }, 500);
  };

  isReadyForRender() {
    return !this.state.notFound && this.state.document;
  }

  setCurrentPostID(currentPostID) {
    this.setState(
      {
        currentPostID
      },
      this.fetchPostContent
    );
  }

  showPostsList() {
    this.setState({ showingPost: false });
  }

  getPostsByIds = async ids => {
    const posts = await this.props.prismicCtx.api.getByIDs(ids);
    return [].concat(...posts.results.map(posts => posts.data.vintage_post));
  };

  getPostsIds = async () => {
    const ids = await this.props.prismicCtx.api.getSingle("vintage_posts", {});
    return ids.data.posts.map(post => post.post_group.id);
  };

  getPosts = async endPostPageIndex => {
    const ids = await this.getPostsIds();
    let posts = await this.getPostsByIds(ids);

    posts = posts.sort(function(a, b) {
      if (a.time_stamp[0].text < b.time_stamp[0].text) {
        return 1;
      }
      if (a.time_stamp[0].text > b.time_stamp[0].text) {
        return -1;
      }
      return 0;
    });

    endPostPageIndex = endPostPageIndex * 50;

    let newPostList = posts.slice(0, endPostPageIndex);

    this.setState({
      vintage_posts: newPostList
    });
  };

  fetchContent() {
    const { contentName } = this.state;
    if (this.props.prismicCtx && contentName) {
      this.props.prismicCtx.api.getSingle(
        contentName,
        {},
        async (err, document) => {
          if (err || !document) {
            this.setState({ document: null, notFound: true });
            throw new Error("Error getting data.", err);
          }
          if (contentName === "something_else") {
            if (!this.state.vintage_posts.length)
              await this.getPosts(this.state.endPostPageIndex);
            document.data.vintage_posts = this.state.vintage_posts;
          }
          this.setState({ document, notFound: false });
        }
      );
    }
  }

  fetchPostContent() {
    this.setState({
      currentPostContent: this.state.currentPostID,
      showingPost: true
    });
  }

  render() {
    const {
      contentName,
      document,
      notFound,
      isOpen,
      showingPost,
      currentPostContent,
      navigationLinks,
      images,
      isLoading,
      isLoadingImages,
      closing,
      vintage_posts
    } = this.state;
    const settings = {
      autoplay: true
    };
    return (
      <div className="home-page-wrapper">
        <div className={`${isLoading ? "hiden" : "fadein"}`}>
          <div className="header">
            <div className="logo" onClick={this.goHomePage}>
              <img src={LogoSvg} />
            </div>
          </div>

          <div className="home-page-content-container">
            <div className="home-page-content">
              {navigationLinks.map((navItem, i) => {
                return (
                  <div
                    key={i}
                    className={`home-page-item ${
                      contentName === `${navItem["resource_name"]}`
                        ? "active"
                        : ""
                    }`}
                  >
                    <div className={`link-text-wrapper${i + 1}`}>
                      <span
                        title={navItem["link_text"]}
                        onClick={() => {
                          this.handleClick(`${navItem["resource_name"]}`);
                        }}
                      >
                        {navItem["link_text"]}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="image-container">
              <div className="image-carousel-container">
                {!isLoadingImages ? (
                  <Carousel
                    speed={300}
                    autoplay={true}
                    withoutControls={true}
                    wrapAround={true}
                  >
                    {images.map((imagesDiv, i) => {
                      return <img key={i} src={imagesDiv.image.url} />;
                    })}
                  </Carousel>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <SidebarOverflow
            isOpen={isOpen}
            closeSidebar={this.closeSidebar}
            showingPost={showingPost}
            showPostsList={this.showPostsList}
            contentName={this.state.contentName}
            closing={closing}
          >
            {contentName === "design" && this.isReadyForRender() && (
              <Design prismicCtx={this.props.prismicCtx} document={document} />
            )}
            {contentName === "sourcing" && this.isReadyForRender() && (
              <Sourcing
                prismicCtx={this.props.prismicCtx}
                document={document}
              />
            )}
            {contentName === "contact" && this.isReadyForRender() && (
              <Contact prismicCtx={this.props.prismicCtx} document={document} />
            )}
            {contentName === "something_else" && this.isReadyForRender() && (
              <SomethingElse
                prismicCtx={this.props.prismicCtx}
                document={document}
                currentPostContent={currentPostContent}
                showingPost={showingPost}
                getNewPosts={this.getPosts}
                vintage_posts={vintage_posts}
              />
            )}
            {notFound && <NotFound />}
            {!notFound && !document && <Loading />}
          </SidebarOverflow>
        </div>
        <div className={`loader ${!isLoading ? "fadeout" : ""}`}>
          <img className="logo" src={LogoSvg} />
        </div>
      </div>
    );
  }
}

export default HomePage;
