import React from 'react';
import './index.css'

const Loading = () => {
    return <div className=''>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>;
};

export default Loading;
