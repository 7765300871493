import React from "react";
import "./index.css";

class Sourcing extends React.Component {
  render() {
    const { document } = this.props;
    return (
      <div className="contact-wrapper" data-wio-id={document.id}>
        <div className="contact-title">
          {document.data.title.map((title, i) => (
            <h2 key={i}>{title.text}</h2>
          ))}
        </div>
        <div className="contact-row-content">
          {document.data.contacts.map((contact, i) => {
            return (
              <div key={i} className="contact-item">
                <h3>{contact.name}</h3>
                <div className="contact-info">
                  <a href={`mailto:${contact.email}`}>
                    <p>{contact.email}</p>
                  </a>
                  <a href={`tel:${contact["first_phone"]}`}>
                    <p>{contact["first_phone"]}</p>
                  </a>
                </div>
              </div>
            );
          })}
        </div>
        <div className="privacy-info">
          <div className="address-wrapper">
            <div className="address">
              {document.data.address.map((item, i) => {
                return <p key={i}>{item.text}</p>;
              })}
              <span>
                <a href="http://www.20ltd.com" target="_blank">
                  www.20ltd.com
                </a>
              </span>
            </div>
          </div>
          <div className="license-wrapper">
            <div className="license">
              {document.data.requisites.map((item, i) => {
                return <p key={i}>{item.text}</p>;
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Sourcing;
