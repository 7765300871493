import React from "react";
import "./index.css";

class Sourcing extends React.Component {
  render() {
    const { document } = this.props;
    return (
      <div className="sourcing-wrapper">
        <div className="sourcing-header">
          {document.data.title.map((title, i) => {
            return <h2 key={i}>{title.text}</h2>;
          })}
        </div>
        <div className="sourcing-content">
          {document.data.description.map((description, i) => {
            return <p key={i}>{description.text}</p>;
          })}
        </div>
      </div>
    );
  }
}

export default Sourcing;
