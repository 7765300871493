import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import NotFound from './NotFound';
import './App.css';
import HomePage from './Components/Pages/HomePage';

const App = (props) => (
    <Router>
        <Switch>
            <Route exact path="/"
                   component={(routeProps) => <HomePage {...routeProps} prismicCtx={props.prismicCtx}/>}/>
            <Route component={NotFound}/>
        </Switch>
    </Router>
);

export default App;
