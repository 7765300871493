import React from "react";
import "./index.css";

export const SidebarOverflow = props => {
  const {
    isOpen,
    contentName,
    showingPost,
    showPostsList,
    closeSidebar,
    closing
  } = props;
  return (
    <div
      className={`sidebar-overflow-content ${contentName} ${
        isOpen ? "slide-in-left" : "slide-in-right"
      } ${closing ? "fadeout" : "fadein"}`}
    >
      <span
        className="close"
        onClick={() => {
          showingPost ? showPostsList() : closeSidebar();
        }}
      >{`<`}</span>
      {props.children}
    </div>
  );
};
